.fv-plugins-icon-container {
  position: relative;
}

.fv-plugins-icon {
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
