$fv-plugins-bootstrap-input-height: 38px;

// See https://getbootstrap.com/docs/4.1/components/forms/#custom-styles
.fv-plugins-bootstrap {
  // Same as Bootstrap `valid-feedback` class
  .fv-help-block {
    color: #dc3545;
    font-size: 80%;
    margin-top: .25rem;
  }

  // Overwrite Bootstrap
  // We don't want to display the color for label
  .is-invalid~.form-check-label,
  .is-valid~.form-check-label {
    color: inherit;
  }

  .has-danger .fv-plugins-icon {
    color: #dc3545;
  }

  .has-success .fv-plugins-icon {
    color: #28a745;
  }

  .fv-plugins-icon {
    height: $fv-plugins-bootstrap-input-height;
    line-height: $fv-plugins-bootstrap-input-height;
    width: $fv-plugins-bootstrap-input-height;
  }

  .input-group~.fv-plugins-icon {
    // Need to have a bigger index than `.input-group .form-control:focus` (which is 2)
    z-index: 3;
  }

  /* For horizontal form */
  .form-group.row {
    .fv-plugins-icon {
      right: 15px;
    }

    .fv-plugins-icon-check {
      top: -7px; /* labelHeight/2 - iconHeight/2 */
    }
  }

  /* Stacked form */
  &:not(.form-inline) {
    label~.fv-plugins-icon {
      top: 32px;
    }

    label~.fv-plugins-icon-check {
      top: 25px;
    }

    // Without labels
    label.sr-only~.fv-plugins-icon-check {
      top: -7px;
    }
  }

  /* Inline form */
  &.form-inline .form-group {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: auto;
  }
}
