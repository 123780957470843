.fv-plugins-semantic {
  &.ui.form .fields.error label,
  .error .fv-plugins-icon {
    color: #9f3a38; /* Same as .ui.form .field.error .input */
  }

  .fv-plugins-icon-check {
    right: 7px;
  }
}
