$fv-plugins-tooltip-bg-color: #000;
$fv-plugins-tooltip-border-width: 8px;
$fv-plugins-tooltip-content-padding: 10px;

.fv-plugins-tooltip {
  position: absolute;
  z-index: 10000;

  .fv-plugins-tooltip__content {
    background: $fv-plugins-tooltip-bg-color;
    border-radius: 3px;
    color: #eee;
    padding: $fv-plugins-tooltip-content-padding;
    position: relative;

    &:before {
      border: $fv-plugins-tooltip-border-width solid transparent;
      content: '';
      position: absolute;
    }
  }
}

.fv-plugins-tooltip--hide {
  opacity: 0;
}

.fv-plugins-tooltip--top-left {
  margin-top: $fv-plugins-tooltip-border-width;

  .fv-plugins-tooltip__content:before {
    border-top-color: $fv-plugins-tooltip-bg-color;
    left: $fv-plugins-tooltip-border-width;
    top: 100%;
  }
}

.fv-plugins-tooltip--top {
  margin-top: $fv-plugins-tooltip-border-width;

  .fv-plugins-tooltip__content:before {
    border-top-color: $fv-plugins-tooltip-bg-color;
    left: 50%;
    margin-left: -$fv-plugins-tooltip-border-width;
    top: 100%;
  }
}

.fv-plugins-tooltip--top-right {
  margin-top: $fv-plugins-tooltip-border-width;

  .fv-plugins-tooltip__content:before {
    border-top-color: $fv-plugins-tooltip-bg-color;
    right: $fv-plugins-tooltip-border-width;
    top: 100%;
  }
}

.fv-plugins-tooltip--right {
  .fv-plugins-tooltip__content:before {
    border-right-color: $fv-plugins-tooltip-bg-color;
    margin-top: -$fv-plugins-tooltip-border-width;
    right: 100%;
    top: 50%;
  }
}

.fv-plugins-tooltip--bottom-right {
  margin-top: $fv-plugins-tooltip-border-width;

  .fv-plugins-tooltip__content:before {
    border-bottom-color: $fv-plugins-tooltip-bg-color;
    bottom: 100%;
    right: $fv-plugins-tooltip-border-width;
  }
}

.fv-plugins-tooltip--bottom {
  margin-top: $fv-plugins-tooltip-border-width;

  .fv-plugins-tooltip__content:before {
    border-bottom-color: $fv-plugins-tooltip-bg-color;
    bottom: 100%;
    left: 50%;
    margin-left: -$fv-plugins-tooltip-border-width;
  }
}

.fv-plugins-tooltip--bottom-left {
  margin-top: $fv-plugins-tooltip-border-width;

  .fv-plugins-tooltip__content:before {
    border-bottom-color: $fv-plugins-tooltip-bg-color;
    bottom: 100%;
    left: $fv-plugins-tooltip-border-width;
  }
}

.fv-plugins-tooltip--left {
  .fv-plugins-tooltip__content:before {
    border-left-color: $fv-plugins-tooltip-bg-color;
    left: 100%;
    margin-top: -$fv-plugins-tooltip-border-width;
    top: 50%;
  }
}

// Some frameworks such as Bootstrap disables `pointer-event` on the feedback icon, so we can not
// trigger the `onClick` event
.fv-plugins-tooltip-icon {
  cursor: pointer;
  pointer-events: inherit;
}
