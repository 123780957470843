.fv-plugins-uikit {
  .fv-plugins-icon {
    height: 40px;   /* Height of UIKit input */
    line-height: 40px;
    top: 25px;      /* Height of UIKit label */
    width: 40px;
  }

  /* Horizontal form */
  &.uk-form-horizontal {
    .fv-plugins-icon {
      top: 0;
    }

    .fv-plugins-icon-check {
      top: -11px;  /* checkboxLabelHeight/2 - iconHeight/2 = 18/2 - 40/2 */
    }
  }

  /* Stacked form */
  &.uk-form-stacked {
    .fv-plugins-icon-check {
      top: 15px;  /* labelHeight + labelMarginBottom + checkboxLabelHeight/2 - iconHeight/2 = 21 + 5 + 18/2 - 40/2 */
    }

    .fv-no-label .fv-plugins-icon {
      top: 0;
    }

    .fv-no-label .fv-plugins-icon-check {
      top: -11px;
    }
  }
}
