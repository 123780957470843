.fv-plugins-pure {
  .fv-plugins-icon {
    height: 36px;
    line-height: 36px;
    width: 36px;    /* Height of Pure input */
  }

  .fv-has-error {
    label,
    .fv-help-block,
    .fv-plugins-icon {
      color: #CA3C3C; /* Same as .button-error */
    }
  }

  .fv-has-success {
    label,
    .fv-help-block,
    .fv-plugins-icon {
      color: #1CB841; /* Same as .button-success */
    }
  }

  /* Horizontal form */
  &.pure-form-aligned {
    .fv-help-block {
      margin-top: 5px;
      margin-left: 180px;
    }

    .fv-plugins-icon-check {
      top: -9px;    /* labelHeight/2 - iconHeight/2 */
    }
  }

  /* Stacked form */
  &.pure-form-stacked {
    .pure-control-group {
      margin-bottom: 8px;
    }

    .fv-plugins-icon {
      top: 22px;      /* Same as height of label */
    }

    .fv-plugins-icon-check {
      top: 13px;
    }

    .fv-sr-only~.fv-plugins-icon {
      top: -9px;
    }
  }
}
